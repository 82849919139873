<template>
    <div>
        <styled-interface
            hide-toolbar
            no-padding>
            <onboarding-header
                :dealer="currentDealer">
                <template #actions>
                    <action-button
                        icon="indicator-error"
                        position="left"
                        light
                        normal-text>
                        Complete &amp; Exit
                    </action-button>
                </template>
            </onboarding-header>

            <div class="pt-5 ma-5">
                <div class="container fluid">
                    <div class="layout align-center justify-center row">
                        <div class="flex">
                            <styled-card
                                hide-header
                                centered
                                simple
                                class="ma-3 pa-4">
                                <div class="layout align-center justify-start row">
                                    <div class="layout align-center justify-start row">
                                        <div>
                                            <img
                                                style="height: 50px;"
                                                :src="`/img/platforms/${channel.icon_name}.svg`">
                                        </div>
                                        <div>
                                            <h2 class="ml-3">
                                                {{ channel.name }} setup complete
                                            </h2>
                                            <div class="ml-3">
                                                Facebook is all setup. Aenean lacinia bibendum nulla sed consectetur. Cum sociis natoque penatibus et magnis dis parturient montes,
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <icon
                                            name="indicator-success"
                                            size="50px"
                                            color="green" />
                                    </div>
                                </div>
                            </styled-card>
                        </div>
                    </div>
                    <div class="layout align-center justify-space-around row px-5">
                        <div class="">
                            <styled-card
                                hide-header
                                centered
                                simple
                                class="my-5 pa-5">
                                <div class="layout align-center justify-start justify-space-around row">
                                    <div class="">
                                        <h2 class="">
                                            Additional Channels
                                        </h2>
                                        <div class="mt-3">
                                            Facebook is all setup. Aenean lacinia bibendum nulla sed consectetur. Cum sociis natoque penatibus.
                                        </div>
                                        <div class="my-5 text-center">
                                            <styled-button>
                                                Setup another channel
                                            </styled-button>
                                        </div>
                                        <div class="layout row justify-space-around text-center">
                                            <div
                                                v-for="(channel, index) in channels"
                                                :key="index">
                                                <div :class="[index % 2 === 0 ? '' : 'icon-margin-top']">
                                                    <img
                                                        style="height: 50px;"
                                                        :src="`/img/platforms/${channel.icon_name}.svg`">
                                                    <div>
                                                        {{ channel.name }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </styled-card>
                        </div>
                        <template v-if="hasPlaybooks">
                            <div class="flex xs2 text-center">
                                <h2>or</h2>
                            </div>
                            <div class="flex">
                                <styled-card
                                    hide-header
                                    centered
                                    simple
                                    class="my-5 pa-5">
                                    <div class="layout align-center justify-start row">
                                        <div>
                                            <h2 class="">
                                                Deploy Facebook Plays
                                            </h2>
                                            <div class="mt-3">
                                                Facebook is all setup. Aenean lacinia bibendum nulla sed consectetur. Cum sociis natoque penatibus et magnis dis parturient montes,
                                            </div>
                                            <div class="my-5 text-center">
                                                <styled-button
                                                    :to="{ name: 'dealer-playbooks', params: { dealer_id: currentDealer.id }}">
                                                    Browse our playbooks
                                                </styled-button>
                                            </div>
                                            <div class="layout pt-4">
                                                <img
                                                    style="width:60%; margin: -50px auto"
                                                    src="/img/playbook_cards.png">
                                            </div>
                                        </div>
                                    </div>
                                </styled-card>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </styled-interface>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import StyledCard from '@/components/globals/StyledCard';
    import StyledButton from '@/components/globals/StyledButton';
    import ActionButton from '@/components/globals/ActionButton';
    import StyledInterface from '@/components/globals/StyledInterface';
    import OnboardingHeader from '@/components/globals/OnboardingHeader';
    import Channels from '@/components/onboarding/channel-setup/channels.js';
    import { getChannelByPlatformName }  from '@/components/onboarding/channel-setup/channels.js';
    import Icon from '@/components/globals/Icon';
    export default {
        components: {
            StyledCard,
            StyledButton,
            ActionButton,
            StyledInterface,
            OnboardingHeader,
            Icon
        },
        data() {
            return {
                channels: Channels,
                channel: {}
            };
        },
        created() {
            this.channel = getChannelByPlatformName(this.$route.params.channel) ?? null;
        },
        computed: {
            ...mapState({
                currentDealer: (state) => state.dealer.currentDealer,
            }),
            hasPlaybooks() {
                return this.channel === getChannelByPlatformName('facebook');
            }
        },
    };
</script>

<style scoped>
.icon-margin-top {
    margin-top: 50px;
}
</style>